@import "https://fonts.googleapis.com/css2?family=Roboto&display=swap";
@import "https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap";
html, body {
  height: 100%;
  width: 100%;
  color: #fff;
  font-family: Roboto, sans-serif;
}

h1 {
  font-family: Nunito, sans-serif;
}

.container {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.error-template {
  text-align: center;
  padding: 40px 15px;
}

.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}

.error-actions .btn {
  margin-right: 10px;
}

/*# sourceMappingURL=404.7e936b7e.css.map */
