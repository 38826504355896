@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');

html, body {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  width: 100%;
  color: white;
}

h1 {
  font-family: 'Nunito', sans-serif;
}

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.error-template {
    padding: 40px 15px;
    text-align: center;
}
.error-actions {
    margin-top: 15px;
    margin-bottom: 15px;
}
.error-actions .btn {
    margin-right: 10px;
}
